import { useForm } from 'react-hook-form'
import { Link, useNavigate } from "react-router-dom"
import Input from "../../ui/Input"
import Button from "../../ui/Button"
import arrow from '../../assets/img/icons/arrow.svg'
import { verifyEmail } from '../../store/actions/auth'
import FormLoader from '../../ui/FormLoader'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

const VerifyForm = ({isAuthLoading, authError, currentUser, onVerifyEmail}) => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()
    const onSubmit = (data) => {
        const formData = {
            email: currentUser?.email,
            verification_code: data.verification_code,
        }
        onVerifyEmail(formData).then(() => {
            toast.success("Trial period activated")
            navigate('/signup-photo')
        })
    }

    return <div className='form-wrap form-wrap__verify'>
        <Link className="arrow" to="/signup">
            <img src={arrow} alt="Back" />
        </Link>
        {isAuthLoading && <FormLoader />}
        <form className='form' onSubmit={handleSubmit(onSubmit)}>
            <h2 className='form__title h2'>{t('authorization.verify_email')}</h2>
            <p className="subtitle_medium form__descr">{t('authorization.sent_verification_code')}</p>
            <Input
                placeholder="Enter code"
                name="verification_code"
                type="text"
                errors={errors}
                register={register}
                validationSchema={{ 
                    required: "Code is required",
                    minLength: {
                        value: 6,
                        message: "Please enter a minimum of 6 characters"
                    },
                    maxLength: {
                        value: 6,
                        message: "Please enter a maximum of 6 characters"
                    }
                }}
            />
            <Button tag="button" disabled={isAuthLoading}>{t('authorization.verify_btn')}</Button>
            <div className='resend_request'>
                <span className='subtitle_medium'>{t('authorization.have_not_received_code')} <br /> <Link className="resend_request__link subtitle_medium_underline">{t('authorization.resend_request')}</Link>  {t('authorization.without_received_code')}</span>
            </div>
            <div className='question'>
                <span className='subtitle_medium'>{t('authorization.text_have_account')} <Link className='question__link subtitle_big subtitle_big_underline' to="/login">{t('authorization.log_in_btn')}</Link></span>
            </div>
            {authError?.response?.status === 401 ? <p className='form__error'>{t('general.incorrect_code_text')}</p> : authError ? <p className='form__error'>{t('general.wrong_message')}</p> : ''}
        </form>
    </div>
}

const mapStateToProps = (state) => {
    const {
        isAuthLoading,
        authError,
        currentUser
    } = state.auth;
    return {
        isAuthLoading,
        authError,
        currentUser
    }
}
const mapDispatchToProps = (dispatch) => ({
    onVerifyEmail: (data) => dispatch(verifyEmail(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(VerifyForm)