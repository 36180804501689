import { useDispatch, useSelector } from "react-redux";
import Layout from "./Layout";
import { Navigate } from "react-router";
import { useEffect, useState } from "react";
import { addOnline, getUserInfo } from "../../store/actions/auth"
import Verify from '../../pages/Verify'
import FormLoader from '../../ui/FormLoader'

const LayoutPrivate = ({ component: Component }) => {
  const [online, setOnline] = useState(false)
  
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
  const currentUser = useSelector(state => state.auth.currentUser)
  const isAuthLoading = useSelector(state => state.auth.isAuthLoading)

  const dispatch = useDispatch()


    useEffect(() => {
      // if (currentUser) {
      //   dispatch(getUserInfo()).then(() => {
          if(!online) {
            dispatch(addOnline()) 
            setOnline(true)
          }
          setInterval(dispatch(addOnline), 120000)
      //   })
      // }
    }, [dispatch, online])


  if (isLoggedIn && (isAuthLoading || !currentUser)) {
    return <Layout><FormLoader /></Layout>
  }

  return isLoggedIn ? 
          currentUser?.email_verified ? <Layout><Component /></Layout> : 
            <Layout><Verify /></Layout> :
          <Navigate to={{pathname: '/login'}} />
}

export default LayoutPrivate