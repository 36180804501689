import axios from 'axios'
import store from '../store/store'
import ActionTypes from '../store/action-types'
import { apiConfig } from '../api/api.config.js'


let isRefreshing = false;
let refreshQueue = [];

const http = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    // withCredentials: true,
})
http.interceptors.request.use(function (config) {
    let token = localStorage.getItem("token");
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
})


http.interceptors.response.use(
    response => response,
    async (error) => {
        console.log(error)
        const originalRequest = error.config;
        if (error.response) {
            if (error.response.status === 401 ) {
                originalRequest._retry = true;

                if (!isRefreshing) {
                    isRefreshing = true;
                    try {
                        const token = localStorage.getItem('token');
                        const response = await axios.post(apiConfig.url + '/refresh', {}, {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            },
                        });
                        if (response?.status === 200) {
                            const access_token = response.data.access_token;
                            localStorage.setItem('token', access_token);
                            originalRequest.headers.Authorization = `Bearer ${access_token}`;
                            // Повторяем все запросы в очереди
                            refreshQueue.forEach(cb => cb(access_token));
                            refreshQueue = [];
                            return http(originalRequest);
                        } else {
                            console.log('error')
                            throw new Error('Failed to refresh token');
                        }
                    } catch (refreshError) {
                        console.error('Error while refreshing token:', refreshError);
                        store.dispatch({ type: ActionTypes.LOGOUT_USER });
                        // return Promise.reject(refreshError);
                    } finally {
                        isRefreshing = false;
                    }
                } else {
                    // Если идет процесс обновления токена, добавляем запрос в очередь
                    return new Promise((resolve, reject) => {
                        refreshQueue.push((token) => {
                            originalRequest.headers.Authorization = `Bearer ${token}`;
                            resolve(http(originalRequest));
                        });
                    });
                }
            } else{
                return Promise.reject(error);
            }
        } else {
            // Обработка ошибок без response (например, сеть не доступна)
            // store.dispatch({ type: ActionTypes.LOGOUT_USER });
            return Promise.reject(error);
        }

    }
);


export { http };
