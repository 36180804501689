import { useTranslation } from 'react-i18next';
import { generateLink } from '../utils/i18n';
const Terms = () => {
    const { t } = useTranslation();
    return <div className='content'>
        <ul className="tabs">
            <li className="active" data-tab="terms">
                <a href={generateLink('/terms-and-conditions')}>Terms and conditions</a>
            </li>
            <li data-tab="privacy">
                <a href={generateLink('/privacy-policy')}>Privacy policy</a>
            </li>
            <li data-tab="offerta">
                <a href={generateLink('/offerta')}>Offert</a>
            </li>
        </ul>
        <div class="container">
            <div class="text_privacy" dangerouslySetInnerHTML={{ __html: t('privacy.text_terms_and_conditions') }} />
        </div>
    </div>
}

export default Terms