import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import fullscreen from '../../assets/img/icons/fullscreen.svg'
import fullscreenExit from '../../assets/img/icons/videochat/fullscreen-exit.svg'
import note from '../../assets/img/icons/note.svg'
import recordingNow from '../../assets/img/icons/recording_now_img.svg'
import voiceIcon from '../../assets/img/icons/voice_icon.svg'
import videoIcon from '../../assets/img/icons/video_icon.svg'
import voiceIconOff from '../../assets/img/icons/videochat/voice-icon_off.svg'
import videoIconOff from '../../assets/img/icons/videochat/video-icon_off.svg'
import stopIcon from '../../assets/img/icons/stop_icon.svg'
import loader from '../../assets/img/icons/loader.svg'
import { useState, useRef, useEffect } from 'react'
import Button from '../../ui/Button'
import Modal from 'react-modal'
import cross from '../../assets/img/icons/cross-white.svg'

import { PopupReportVideo } from '../../components/Popups/Popups'
import {callFriend, endCall, getVideoLimits, isOnCall, saveVideo} from '../../store/actions/videochat'
import {refreshAuthToken} from '../../store/actions/auth';
import ChatName from './components/ChatName'
import { useDispatch, useSelector } from 'react-redux'
import createPeer from '../../hooks/createPeer'
import { useTranslation } from 'react-i18next'
import { useBeforeUnload, useBlocker } from 'react-router-dom';

import Echo from 'laravel-echo'
import { toast } from 'react-toastify'


const FriendCallMain = () => {
    const [isLoading, setLoading] = useState(false)
    const [isRecording, setRecord] = useState(false)
    const [isChatStarted, setChatStarted] = useState(false)
    const [companionData, setCompanionData] = useState(null)
    const [isVoiceSetting, setVoiceSetting] = useState(true)
    const [isVideoSetting, setVideoSetting] = useState(true)
    const [isFullscreen, setFullscreen] = useState(false)
    const [reportVideoPopup, setReportVideoPopup] = useState(false)
    const [stopCallListener, setStopCallListener] = useState(false)
    const [recordingLoading, setRecordingLoading] = useState(false)
    const [recordTimer, setRecordTimer] = useState(false)
    const videoRef = useRef(null)
    const peerInstance = useRef(null);
    const remoteVideoRef = useRef(null);
    const currentUserVideoRef = useRef(null)
    const companionIdInstance = useRef(null)
    const navigationTimeoutRef = useRef(null);
    const [isNavigationVisible, setNavigationVisible] = useState(true);

    const user = useSelector(state => state.auth.currentUser)
    const dispatch = useDispatch()

    const mediaRecorderInstance = useRef(null)
    const navigate = useNavigate()
    const echoLaravel = useRef(null);

    const connectToChannel = (token) => {
        if (echoLaravel.current) {
            echoLaravel.current.disconnect();
        }

        echoLaravel.current = new Echo({
            broadcaster: 'pusher',
            key: process.env.REACT_APP_PUSHER_KEY,
            cluster: 'eu',
            forceTLS: true,
            auth: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            },
            authEndpoint: `${process.env.REACT_APP_URL}/broadcasting/auth`
        });

        echoLaravel.current.join('video-chat')
            .listen('VideochatChanel', async (event) => {
                if (event?.data?.type === 'andCall') {
                    setCompanionData(null);
                    if (+event.data.to === user.id) {
                        console.log('end call');
                        peerInstance.current.destroy();
                        if (currentUserVideoRef?.current?.srcObject) {
                            currentUserVideoRef.current.srcObject.getTracks().forEach(track => track.stop());
                            currentUserVideoRef.current.srcObject = null;
                            remoteVideoRef.current.srcObject = null;
                            const requestDataEndCall = { isOnCall: false };
                            dispatch(isOnCall(requestDataEndCall));
                            setVoiceSetting(true)
                            setVideoSetting(true)
                            return navigate('/');
                        }
                    }
                }
            })
            .subscribed(() => {
                console.log('Connected to channel VideochatChanel');
            })
            .error(async (error) => {
                console.error('Channel error:', error);
                if (error.type === 'AuthError') {
                    try {
                        const newToken = await dispatch(refreshAuthToken());
                        if (newToken) {
                            connectToChannel(newToken);
                        }
                    } catch (tokenError) {
                        console.error('Token refresh error:', tokenError);
                    }
                }
            });
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const userToCall = searchParams.get('userId');
        const incomingCallFrom = searchParams.get('incomingCallFrom');
        setLoading(true);

        const peer = createPeer(user.id);
        peer.on('open', (id) => {
            console.log('Peer ID: ' + id);
            peerInstance.current = peer;

            if (userToCall) {
                handleUserToCall(userToCall);
            }

            if (incomingCallFrom) {
                handleIncomingCallFrom(incomingCallFrom);
            }
        });

        peer.on('call', (call) => {
            const mediaStream = currentUserVideoRef.current.srcObject;

            call.answer(mediaStream);
            call.on('stream', function (remoteStream) {
                remoteVideoRef.current.srcObject = remoteStream;
                const requestData = { isOnCall: true };
                dispatch(isOnCall(requestData));
            });

            if (call.metadata) {
                companionIdInstance.current = call.metadata.userId;
                const tempCompData = { ...call.metadata };
                setCompanionData(tempCompData);
                setLoading(false);
            }
        });



        const token = localStorage.getItem('token');
        if (token) {
            connectToChannel(token);
        }

        return () => {
            if (currentUserVideoRef?.current?.srcObject) {
                currentUserVideoRef.current.srcObject.getTracks().forEach(track => track.stop());
                currentUserVideoRef.current.srcObject = null;
            }
            if (peer) {
                peer.destroy();
            }
        };
    }, []);

    useEffect(() => {
        const resetNavigationTimeout = () => {
            if (navigationTimeoutRef.current) {
                clearTimeout(navigationTimeoutRef.current);
            }
            setNavigationVisible(true);
            navigationTimeoutRef.current = setTimeout(() => {
                setNavigationVisible(false);
            }, 2000); // 2 секунд
        };

        const handleMouseMove = () => {
            resetNavigationTimeout();
        };

        videoRef.current.addEventListener('mousemove', handleMouseMove);

        return () => {
            if (navigationTimeoutRef.current) {
                clearTimeout(navigationTimeoutRef.current);
            }
            if (videoRef.current) {
                videoRef.current.removeEventListener('mousemove', handleMouseMove);
            }
        };
    }, []);

    useBeforeUnload((event) => {
        if (isChatStarted) {
          stopCall();
          event.preventDefault();
          event.returnValue = ''; 
        }
      });


    const blocker = useBlocker(() => !!isChatStarted);
    useEffect(() => {
        if (blocker.state === 'blocked' && isChatStarted) {
        console.log('stopCall')
        stopCall().then(() => {
            blocker.proceed();
        });
        }
    }, [blocker.state]);

    const handleUserToCall = (userToCall) => {
        const requestData = { user_to_call: userToCall };
        dispatch(callFriend(requestData)).then(data => {
            setLoading(false);
            var getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

            if (currentUserVideoRef.current.srcObject === null) {
                setChatStarted(true);
                getUserMedia({ video: true, audio: true }, (mediaStream) => {
                    currentUserVideoRef.current.srcObject = mediaStream;
                    setLoading(false);
                });
            }
        }).catch(error => {
            setLoading(false);
            console.error('Error:', error);
        });
    };

    const handleIncomingCallFrom = (incomingCallFrom) => {
        var getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
        getUserMedia({ video: true, audio: true }, (mediaStream) => {
            currentUserVideoRef.current.srcObject = mediaStream;
            setLoading(false);
            const userId = incomingCallFrom;
            companionIdInstance.current = userId;
            setChatStarted(true);
            if (peerInstance.current) {
                const callInstance = peerInstance.current.call(userId + "parrot2024", mediaStream, {
                    metadata: {
                        userId: user.id,
                        name: user.name,
                        pet_gender: user.pet_gender,
                        pet_name: user.pet_name,
                        friends: user.friends
                    }
                });

                setCompanionData(incomingCallFrom);

                callInstance.on('stream', (remoteStream) => {
                    remoteVideoRef.current.srcObject = remoteStream;
                    const requestData = { isOnCall: true };
                    dispatch(isOnCall(requestData));
                });
            }
        });
    };


    const VideoSetting = () => {
        if (currentUserVideoRef.current?.srcObject){
            setVideoSetting(!isVideoSetting)
            const videoTracks = currentUserVideoRef.current.srcObject.getVideoTracks();
            videoTracks.forEach(track => {
                track.enabled = !track.enabled;
            });    
        }   
    }

    const VoiceSetting = () => {
        if (currentUserVideoRef.current?.srcObject){
            setVoiceSetting(!isVoiceSetting)
            const audioTracks = currentUserVideoRef.current.srcObject.getAudioTracks();
            audioTracks.forEach(track => {
                track.enabled = !track.enabled;
            });
        }   
    }

    const stopCall = async () => {
        setStopCallListener(!stopCallListener)
        setLoading(true)
        setCompanionData(null)
        setChatStarted(false)
        stopRecording()
        setVoiceSetting(true);
        setVideoSetting(true)
        const requestData = {};
        if (peerInstance.current && peerInstance.current.connections) {
            const connections = Object.values(peerInstance.current.connections);
            let isDisconnected = true;
    
            connections.forEach(connectionsArray => {
                connectionsArray.forEach(connection => {
                    if (connection.open) {
                        isDisconnected = false;
                    }
                });
            });
    
            if (isDisconnected) {
                companionIdInstance.current = null;
            }
        }
        requestData.to = companionIdInstance.current
        peerInstance.current.destroy();
        if (currentUserVideoRef?.current?.srcObject) {
            currentUserVideoRef.current.srcObject.getTracks().forEach(track => track.stop());
            currentUserVideoRef.current.srcObject = null;
            remoteVideoRef.current.srcObject = null;
        }      

        dispatch(endCall(requestData)).then(() => {
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            console.error('Ошибка при выполнении end call from component stop call func:', error)
        })
        const requestDataEndCall = {
            isOnCall: false
        }
        dispatch(isOnCall(requestDataEndCall))
        return navigate('/');
    }



    const onStartRecord = async e => {
        e.preventDefault();
        dispatch(getVideoLimits()).then(response => {
            const result = response?.data
            if(result === true){
                setRecordTimer(setTimeout(stopRecording, 300000))
                startRecording()
            } else {
                toast.warn("You have used up your limit.")
            } 
        }).catch(err => {
            console.error('There was an error!', err)
        })
    };
    const onStopRecord = e => {
        e.preventDefault()
        clearTimeout(recordTimer)
        stopRecording()
    }

   
    const startRecording = () => {
        if (remoteVideoRef.current.srcObject && currentUserVideoRef.current.srcObject) {
            const remoteVideo = remoteVideoRef.current;
            const currentUserVideo = currentUserVideoRef.current;
    
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
    
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            const destination = audioContext.createMediaStreamDestination();
    
            const remoteAudioSource = audioContext.createMediaStreamSource(remoteVideo.srcObject);
            remoteAudioSource.connect(destination);
    
            const currentUserAudioSource = audioContext.createMediaStreamSource(currentUserVideo.srcObject);
            currentUserAudioSource.connect(destination);
    
            const canvasStream = canvas.captureStream(30); // Устанавливаем частоту кадров
            const combinedStream = new MediaStream([
                ...canvasStream.getVideoTracks(),
                ...destination.stream.getAudioTracks()
            ]);
    
            // Определяем тип браузера и устанавливаем параметры MediaRecorder
            let options = { mimeType: 'video/webm; codecs=vp9' }; // Chrome
            const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    
            if (isSafari) {
                options = { mimeType: 'video/mp4' }; // Safari
            }
            const recorder = new MediaRecorder(combinedStream, options);
            const chunks = [];
    
            recorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    chunks.push(event.data);
                }
            };
    
            recorder.onstop = async () => {
                const blob = new Blob(chunks, { type: options.mimeType });
                const formData = new FormData();
                formData.append('video', blob);
    
                try {
                    const response = await dispatch(saveVideo(formData)).then(() => {
                        toast.success("Video saved successfully")
                    }).catch(() => {
                        toast.error("Failed saved video. Please try again.")
                    });
                    setRecordingLoading(false);
                } catch (e) {
                    console.log('save video error', e);
                    toast.error("Failed saved video. Please try again.")
                }
            };
    
            recorder.start();
    
            const drawVideoStreams = () => {
                const remoteWidth = remoteVideo.videoWidth;
                const remoteHeight = remoteVideo.videoHeight;
                const currentUserWidth = currentUserVideo.videoWidth;
                const currentUserHeight = currentUserVideo.videoHeight;
    
                canvas.width = remoteWidth;
                canvas.height = remoteHeight;
    
                ctx.drawImage(remoteVideo, 0, 0, remoteWidth, remoteHeight);
    
                const padding = 20;
                const currentUserScaledWidth = 150;
                const currentUserScaledHeight = (currentUserHeight / currentUserWidth) * currentUserScaledWidth;
                const currentUserX = padding;
                const currentUserY = padding;
                ctx.drawImage(currentUserVideo, currentUserX, currentUserY, currentUserScaledWidth, currentUserScaledHeight);
    
                requestAnimationFrame(drawVideoStreams);
            };
    
            drawVideoStreams();
            mediaRecorderInstance.current = recorder;
            setRecord(true);
        }
    };
    
    const stopRecording = () => {
        if (mediaRecorderInstance?.current) {
            setRecordingLoading(true)
            mediaRecorderInstance.current.stop();
            setRecord(false);
        }
    } 

    const onFullScreen = () => {
        const isIPhone = (navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i))

        if (isFullscreen) {
            if (isIPhone) {
                onFullScreenIphone()
                return
            }
            if (videoRef.webkitSupportsFullscreen) {
                videoRef.webkitExitFullscreen()()
                setFullscreen(!isFullscreen)
                return
            }
            document.exitFullscreen()
            setFullscreen(!isFullscreen)
            return
        }

        if (isIPhone) {
            onFullScreenIphone()
            return
        }

        if (videoRef.webkitSupportsFullscreen) {
            videoRef.webkitEnterFullscreen()
            setFullscreen(!isFullscreen)
            return
        }

        videoRef.current.requestFullscreen()
        setFullscreen(!isFullscreen)
    }

    const onFullScreenIphone = () => {
        setFullscreen(!isFullscreen)
        isFullscreen ? videoRef.current.classList.remove('opened') : videoRef.current.classList.add('opened')
    }

    const openReportVideoModal = (e) => {
        e.preventDefault()
        setReportVideoPopup(true)
    }
    const closeReportVideoModal = () => {
        setReportVideoPopup(false)
    }
    const { t } = useTranslation()

    return <div className='container'>
    <div className='videochat' ref={videoRef}>
        <div className='videochat__header'>
            <div className='left_header_info'>
                <div className='fullsize cursor-pointer' onClick={onFullScreen}>
                    {isFullscreen ? <img src={fullscreenExit} alt="Full screen" /> : <img src={fullscreen} alt="Full screen" />}
                </div>
                <div className='note cursor-pointer' onClick={openReportVideoModal}>
                    <img src={note} alt="Report" />
                    <span className='subtitle_big'>{t('home.report_violation')} </span>
                </div>
            </div>
            <ChatName />
            {isRecording ? <div className='recording'>
                    <span className='subtitle_big'>{t('home.chat_recording_message')}</span>
                    <img src={recordingNow} alt="Record" />
                </div> : <div className='w-[230px] hidden md:block'></div>}
            
        </div>
        <div className='fotouser'>
            
            {!isChatStarted && <>
                {/* <div className="video-overlay"></div> */}
                <div className="overlay-heading">
                    <p className='h2'>{t('home.insist_start_chat')}</p>
                </div>
            </>}
            {isLoading && <div className='videochat__loader animate-spin'><img src={loader} alt='Loader' /></div>}
            <video 
                autoPlay 
                playsInline
                className="cursor-pointer user-video"
                ref={remoteVideoRef}
            ></video>
        </div>
        <div>
            <div className={`another_user ${isChatStarted ? 'active' : ''}`}>
                <video muted playsInline autoPlay ref={currentUserVideoRef}></video>
            </div>
            {/* {companionData ? <TopControls user={companionData} /> : ''} */}
        </div>
        <div className={`videochat__navigation ${isNavigationVisible ? 'visible' : 'hidden'}`}>
            <div className='settings'>
                <div className={`settings__item ${!isVoiceSetting ? 'disabled' : ''}`} onClick={VoiceSetting}>
                    {isVoiceSetting ? <img src={voiceIcon} alt="Voice" /> : <img src={voiceIconOff} alt="Voice" />}
                </div>
                <div className={`settings__item ${!isVideoSetting ? 'disabled' : ''}`} onClick={VideoSetting} >
                    {isVideoSetting ? <img src={videoIcon} alt="Video" /> : <img src={videoIconOff} alt="Video" />}
                </div>
            </div>
            <div className='buttons_start_end'>
                {!isChatStarted ?
                    <Button tag="button" className='btn_stop' iconLeft={stopIcon} onClick={stopCall}>{t('home.stop')}</Button>:
                    <Button tag="button" className='btn_stop' iconLeft={stopIcon} onClick={stopCall}>{t('home.stop')}</Button>
                }
            </div>
            {isRecording ? <Link to="/" className={`recording_stop ${recordingLoading ? 'loading' : ''}`} 
                onClick={(e) => {onStopRecord(e)}}
            >
                <span className={`record-icon recording`}></span>
                <div className='animate-spin'><img src={loader} alt='Loader' /></div>
                <span className='subtitle_medium'>
                    {recordingLoading ? 'Loading' : t('home.stop_recording')}
                </span>
            </Link> : <Link to="/" className={`recording_stop ${recordingLoading ? 'loading' : ''}`} 
                onClick={(e) => {onStartRecord(e)}}
            >
                <span className={`record-icon`}></span>
                <div className='animate-spin'><img src={loader} alt='Loader' /></div>
                <span className='subtitle_medium'>
                    {recordingLoading ? 'Loading' : t('home.start_recording')}
                </span>
            </Link>}
        </div>
    </div>
    <Modal
        isOpen={reportVideoPopup}
        onRequestClose={closeReportVideoModal}
        className="popup"
        overlayClassName="popup__overlay"
        shouldCloseOnOverlayClick={true}
    >
        <div className="popup__content" >
            <div className="popup__close" onClick={closeReportVideoModal}><img src={cross} alt="Close" /></div>
            <PopupReportVideo />
        </div>
    </Modal>
</div>
}

export default React.memo(FriendCallMain)