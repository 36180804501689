import { Link } from 'react-router-dom'
import arrow from '../assets/img/icons/arrow.svg'
import arrow_dark from '../assets/img/icons/arrow_dark.svg' 
import {useCookies} from 'react-cookie'
import { generateLink } from '../utils/i18n'
import { useTranslation } from 'react-i18next'

const BackLink = () => {
    const [cookies] = useCookies(['theme', 'language'])
    const { t } = useTranslation()

    return <Link to={generateLink('/')} className='back-link'>
        <img src={cookies?.theme === 'dark' ? arrow : arrow_dark} alt="link to back" />
        <span className='subtitle_big'>{t('general.back_chat')}</span>
    </Link>
}
export default BackLink