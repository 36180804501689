import notification from '../../assets/img/icons/bell.svg'
import cross from '../../assets/img/icons/cross-white.svg'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { acceptFriendRequest, getFriendsRequests } from '../../store/actions/friends/friends'
import {refreshAuthToken} from '../../store/actions/auth';
import FormLoader from '../../ui/FormLoader'
import { DefaultAvatar, storageUrl } from '../../utils/Utils'
import addfriendIcon from '../../assets/img/icons/add_friends.svg'
import Button from '../../ui/Button'
import { echoLaravel } from '../../utils/Utils'
import gender_boy from "../../assets/img/icons/gender-boy.svg"
import gender_boy_dark from "../../assets/img/icons/gender-boy-dark.svg"
import gender_girl from "../../assets/img/icons/gender-girl.svg"

import { useNavigate } from "react-router-dom"
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'

import Pusher from 'pusher-js'
import Echo from 'laravel-echo'
import { toast } from 'react-toastify'

const NavbarNotifications = ({notificationPopup, toggleNotifications, notificationMenu, sendDataToParent, sendInvitesToParent}) => {

    const friendsRequests = useSelector(state => state.friends.friendsRequests)
    const isFriendsLoading = useSelector(state => state.friends.isFriendsLoading)
    const currentUser = useSelector(state => state.auth.currentUser)
    const dispatch = useDispatch()
    const [cookies] = useCookies(['theme'])
    const { t } = useTranslation()

    const navigate = useNavigate()
    const [invitesList, setInvitesList] = useState([])

    const connectToChannel = (token) => {
        if (echoLaravel.current) {
            echoLaravel.current.disconnect();
        }

        echoLaravel.current = new Echo({
            broadcaster: 'pusher',
            key: process.env.REACT_APP_PUSHER_KEY,
            cluster: 'eu',
            forceTLS: true,
            auth: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            },
            authEndpoint: `${process.env.REACT_APP_URL}/broadcasting/auth`
        });

        echoLaravel.current.join('friends-chanel')
        .listen('FriendsChanel', async (event) => {
            if (currentUser && event?.data?.type === 'sendFriendRequest' && +event.data.recipientId === currentUser.id) {
                dispatch(getFriendsRequests())
            }
            if (currentUser && event?.data?.type === 'incomingCall' && +event.data.userToCall === currentUser.id) {
                let inviteMatch = false
                invitesList.forEach(e => {
                    if(event.data.from === e.from) inviteMatch = true
                })
                if(!inviteMatch) setInvitesList([...invitesList, event.data])
            }
        })
        .subscribed(() => {
            console.log('Connected to channel FriendsChanel');
        })
        .error(async (error) => {
            console.error('Channel error:', error);
            if (error.type === 'AuthError') {
                try {
                    const newToken = await dispatch(refreshAuthToken());
                    if (newToken) {
                        connectToChannel(newToken);
                    }
                } catch (tokenError) {
                    console.error('Token refresh error:', tokenError);
                }
            }
        });
    };

    useEffect(() => {
        if (currentUser) {
            const token = localStorage.getItem('token');
            if (token) {
                connectToChannel(token);
            }
        }
    }, [dispatch, currentUser])

    useEffect(() => {
        dispatch(getFriendsRequests())
    }, [dispatch])

    useEffect(() => {
        sendDataToParent(friendsRequests)
    }, [sendDataToParent, friendsRequests])

    useEffect(() => {
        sendInvitesToParent(invitesList)
    }, [sendInvitesToParent, invitesList])

    const onAcceptFriend = (id) => {
        // dispatch(acceptFriendRequest(id))

        dispatch(acceptFriendRequest(id)).then(() => {
            toast.success("Added to friends successfully")
        }).catch(() => {
            toast.error("Failed added to friends. Please try again.")
        });
    }

    const acceptCall = data => {
        return navigate('/friend-call?incomingCallFrom='+data);
    };

    

    return isFriendsLoading ? <FormLoader /> :
    <div className={`notification__popup ${notificationPopup ? 'active' : ''}`} ref={notificationMenu}>
        <div className="popup__head">
            <div className='flex items-center'>
                <div className={`icon-wrap ${friendsRequests?.length ? 'unread' : ''}`} >
                    <svg
                    width="40.000000"
                    height="40.000000"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                    <desc>Created with Pixso.</desc>
                    <defs/>
                    <path
                    id="Vector"
                    d="M8.66797 29.285L29.666 29.285C30.2939 29.285 30.7842 29.1283 31.1367 28.8148C31.4902 28.5013 31.667 28.0832 31.667 27.5606C31.667 27.0856 31.5391 26.6248 31.2832 26.1782C31.0264 25.7316 30.7061 25.2946 30.3223 24.8671C29.9385 24.4395 29.5557 24.0119 29.1768 23.5844C28.8848 23.2613 28.6572 22.8384 28.4941 22.3159C28.3311 21.7933 28.209 21.2375 28.1299 20.6486C28.0498 20.0594 27.9932 19.4894 27.957 18.9383C27.9307 17.114 27.748 15.4893 27.4072 14.0641C27.0674 12.639 26.54 11.4418 25.8242 10.4727C25.1094 9.5036 24.1641 8.79102 22.9893 8.3349C22.7422 7.40381 22.2871 6.61517 21.625 5.96912C20.9619 5.32306 20.1406 5 19.1602 5C18.1885 5 17.3721 5.32306 16.709 5.96912C16.0469 6.61517 15.5918 7.40381 15.3447 8.3349C14.1699 8.79102 13.2227 9.5036 12.5029 10.4727C11.7832 11.4418 11.2559 12.639 10.9199 14.0641C10.584 15.4893 10.4033 17.114 10.377 18.9383C10.3408 19.4894 10.2842 20.0594 10.2041 20.6486C10.125 21.2375 10.0039 21.7933 9.83984 22.3159C9.67676 22.8384 9.44922 23.2613 9.1582 23.5844C8.77832 24.0119 8.39551 24.4395 8.01172 24.8671C7.62793 25.2946 7.30762 25.7316 7.05078 26.1782C6.79492 26.6248 6.66699 27.0856 6.66699 27.5606C6.66699 28.0832 6.84375 28.5013 7.19727 28.8148C7.5498 29.1283 8.04004 29.285 8.66797 29.285ZM19.1602 35C19.9111 35 20.582 34.8242 21.1738 34.4727C21.7656 34.1212 22.2412 33.6604 22.5986 33.0903C22.9561 32.5202 23.166 31.9026 23.2275 31.2375L15.1064 31.2375C15.1592 31.9026 15.3672 32.5202 15.7295 33.0903C16.0908 33.6604 16.5684 34.1212 17.1602 34.4727C17.752 34.8242 18.4189 35 19.1602 35Z"
                    fill="#94A8B4"
                    fillOpacity="1.000000"
                    fillRule="nonzero"
                    />
                </svg>
                    {/* <img src={notification} alt="Notification" /> */}
                </div>
                <p className='h3'>{t('general.navbar_unread_messages')}</p>
            </div>
            <button 
                onClick={toggleNotifications}
            ><img src={cross} alt="Close" /></button>
        </div>
        {friendsRequests?.length || invitesList?.length ? (
        <div className="notification__list">
            {invitesList?.length ? invitesList.map((n) => (
            <div className="notification__item" key={n.from}>
                <div className='flex items-center'>
                    <div className="img-wrap">
                        {n.image ? <img src={`${storageUrl}/${n.image}`} alt={n.fromName} /> : <DefaultAvatar alt={n.fromName} />}
                    </div>
                    <div className="text">
                        <div className="flex items-center">
                            <p className='h4 mr-[10px]'>{n.petName}</p>
                            {n.petGender && <img src={n.petGender === 'boy' ? cookies?.theme === 'dark' ? gender_boy : gender_boy_dark : gender_girl} alt="Gender" className="mr-[10px]" />}
                            <p className='subtitle_medium'>{n.petBreedName}</p>
                        </div>
                        <p className='h4'>{n.fromName}</p>
                    </div>
                </div>
                <Button tag="button" className='btn_primary btn' onClick={() => acceptCall(n.from)}>{t('general.navbar_join_chat_btn')}</Button>
            </div>
            )) : ''}
            {friendsRequests?.length ? friendsRequests.map((n) => (
            <div className="notification__item" key={n.id}>
                <div className='flex items-center'>
                    <div className="img-wrap">
                        {n.image ? <img src={`${storageUrl}/${n.image}`} alt={n.name} /> : <DefaultAvatar alt={n.name} />}
                    </div>
                    <div className="text">
                        <div className="flex items-center">
                            <p className='h4 mr-[10px]'>{n.pet_name}</p>
                            {n.pet_gender && <img src={n.pet_gender === 'boy' ? cookies?.theme === 'dark' ? gender_boy : gender_boy_dark : gender_girl} alt="Gender" className="mr-[10px]" />}
                            <p className='subtitle_medium'>{n.breed?.name}</p>
                        </div>
                        <p className='h4'>{n.name}</p>
                    </div>
                </div>
                <Button tag="button" className='add_friend btn' onClick={() => onAcceptFriend(n.id)}>
                    <span className='h4'>{t('general.navbar_add_friend_btn')}</span>
                    <img src={addfriendIcon} alt="Add friend" />
                </Button>
            </div>
            )) : ''}
        </div>
        ) : (
            <div className='notification_empty'>
                <p className='subtitle_big'>{t('general.navbar_no_invitation_to_chat')}</p>
            </div>
        )}     
    </div>

    
}

export default NavbarNotifications