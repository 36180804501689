import React from 'react'
import {Link} from 'react-router-dom'
import fullscreen from '../../assets/img/icons/fullscreen.svg'
import fullscreenExit from '../../assets/img/icons/videochat/fullscreen-exit.svg'
import note from '../../assets/img/icons/note.svg'
import recordingNow from '../../assets/img/icons/recording_now_img.svg'
import voiceIcon from '../../assets/img/icons/voice_icon.svg'
import videoIcon from '../../assets/img/icons/video_icon.svg'
import voiceIconOff from '../../assets/img/icons/videochat/voice-icon_off.svg'
import videoIconOff from '../../assets/img/icons/videochat/video-icon_off.svg'
import stopIcon from '../../assets/img/icons/stop_icon.svg'
import nextIcon from '../../assets/img/icons/next_icon_btn.svg'
import loader from '../../assets/img/icons/loader.svg'
import { useState, useRef, useEffect } from 'react'
import Button from '../../ui/Button'
import Modal from 'react-modal'
import cross from '../../assets/img/icons/cross-white.svg'

import { PopupReportVideo } from '../../components/Popups/Popups'
import {endCall, getVideoLimits, getTimeLimit, getVideochatPeerId, isOnCall, saveVideo} from '../../store/actions/videochat'
import {refreshAuthToken} from '../../store/actions/auth';
import ChatName from './components/ChatName'
import TopControls from './components/TopControl'
import { useDispatch, useSelector } from 'react-redux'
import Cookies from 'js-cookie'
import createPeer from '../../hooks/createPeer'
import { useTranslation } from 'react-i18next'
import { useBeforeUnload, useBlocker } from 'react-router-dom';

import Echo from 'laravel-echo'
import { toast } from 'react-toastify'
import { useSearchParams } from 'react-router-dom';

const Videochat = () => {
    const [isLoading, setLoading] = useState(false)
    const [isRecording, setRecord] = useState(false)
    const [isChatStarted, setChatStarted] = useState(false)
    const [companionData, setCompanionData] = useState(null)
    const [isVoiceSetting, setVoiceSetting] = useState(true)
    const [isVideoSetting, setVideoSetting] = useState(true)
    const [isFullscreen, setFullscreen] = useState(false)
    const [reportVideoPopup, setReportVideoPopup] = useState(false)
    const [stopCallListener, setStopCallListener] = useState(false)
    const [recordingLoading, setRecordingLoading] = useState(false)
    const [recordTimer, setRecordTimer] = useState(false)
    const [isAbleToStart, setAbilityToStart] = useState(false) //User can start chat only if he gets the peer id
    const videoRef = useRef(null)
    const peerInstance = useRef(null);
    const remoteVideoRef = useRef(null);
    const currentUserVideoRef = useRef(null)
    const companionIdInstance = useRef(null)
    const navigationTimeoutRef = useRef(null);
    const videoTimeLimit = useRef(null);
    const [isNavigationVisible, setNavigationVisible] = useState(true)
    const [searchParams] = useSearchParams()


    const user = useSelector(state => state.auth.currentUser)
    const dispatch = useDispatch()
    const mediaRecorderInstance = useRef(null)
    const echoLaravel = useRef(null);



    const connectToChannel = (token) => {
        if (echoLaravel.current) {
            echoLaravel.current.disconnect();
        }

        echoLaravel.current = new Echo({
            broadcaster: 'pusher',
            key: process.env.REACT_APP_PUSHER_KEY,
            cluster: 'eu',
            forceTLS: true,
            auth: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            },
            authEndpoint: `${process.env.REACT_APP_URL}/broadcasting/auth`
        });

        echoLaravel.current.join('video-chat')
            .listen('VideochatChanel', async (event) => {
                if (event?.data?.type === 'andCall') {
                    if (+event.data.to === user.id) {
                        console.log('end call')
                        setCompanionData(null);
                        let excludeIds = Cookies.get('exclude_ids') || '[]';
                        excludeIds = JSON.parse(excludeIds);
                        if (!excludeIds.includes(+event.data.from)) {
                            excludeIds.push(+event.data.from);
                            if (excludeIds.length > 2) {
                                excludeIds.shift();
                            }
                            Cookies.set('exclude_ids', JSON.stringify(excludeIds), { expires: 1, path: '/', secure: false, sameSite: 'strict' });
                        }
                        Cookies.set('exclude_ids', JSON.stringify(excludeIds), { expires: 1, path: '/', secure: false, sameSite: 'strict' });
                        if (currentUserVideoRef?.current?.srcObject) {
                            remoteVideoRef.current.srcObject = null;
                            currentUserVideoRef.current.srcObject.getTracks().forEach(track => track.stop());
                            currentUserVideoRef.current.srcObject = null;
                        }
                        companionIdInstance.current = null;
                        const requestData = {};
                        requestData.notEnd = true; 
                        dispatch(endCall(requestData)).then(() => {
                            setLoading(false);
                        
                            const requestDataEndCall = {
                                isOnCall: false
                            }
                            dispatch(isOnCall(requestDataEndCall)).then(() => {
                                call();
                            })
                        })
                       
                    
                    }
                }
            })
            .subscribed(() => {
                console.log('Connected to channel VideochatChanel');
            })
            .error(async (error) => {
                console.error('Channel error:', error);
                if (error.type === 'AuthError') {
                    try {
                        const newToken = await dispatch(refreshAuthToken());
                        if (newToken) {
                            connectToChannel(newToken);
                        }
                    } catch (tokenError) {
                        console.error('Token refresh error:', tokenError);
                    }
                }
            });
    };

    useEffect(() => {
        const requestData = {}
        requestData.to = null
        dispatch(endCall(requestData)).then(() => {
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            console.error('Ошибка при выполнении end call from useeffect in component:', error)
        })

        dispatch(getTimeLimit()).then(response => {
            const result = response?.data
            videoTimeLimit.current = result * 60 * 1000;
        }).catch(err => {
            console.error('There was an error!', err)
        })

        if (user) {
            const token = localStorage.getItem('token');
            if (token) {
                connectToChannel(token);
            }
        }
    
        const peer = createPeer(user.id)
           
        peer.on('open', (id) => {
          console.log('My peer ID is: ' + id)
          peerInstance.current = peer
          setAbilityToStart(id)
        });    
        if(!companionIdInstance.current){
            peer.on('call', (call) => {

                const mediaStream = currentUserVideoRef.current.srcObject;
    
                call.answer(mediaStream)
                call.on('stream', function(remoteStream) {
                    console.log('remoteStream', remoteStream);

                    if (remoteStream.getVideoTracks().length > 0) {
                        remoteVideoRef.current.srcObject = remoteStream;
                        console.log('remoteVideoRef', remoteVideoRef.current.srcObject);
                        
                        const requestData = {
                            isOnCall: true
                        };
                        dispatch(isOnCall(requestData));
                    } else {
                        console.log('Удаленный поток не содержит видео треков, повторная попытка вызова');
                        setTimeout(nextCall, 1000);
                    }
                });
                if (call.metadata){
                    companionIdInstance.current = call.metadata.userId
                    console.log('call.metadata', call.metadata)
                    const tempCompData = {
                        id: call.metadata.userId,
                        name: call.metadata.name,
                        pet_name: call.metadata.pet_name,
                        pet_gender: call.metadata.pet_gender,
                        friends: call.metadata.friends,
                    }
                    setCompanionData(tempCompData)
                    setLoading(false)
                }
            
            }) 
        } 
        
        return () => {
            if (currentUserVideoRef?.current?.srcObject) {
                currentUserVideoRef.current.srcObject.getTracks().forEach(track => track.stop());
                currentUserVideoRef.current.srcObject = null;
            }
            if (peer) {
                peer.destroy()
            }
        }
        
    }, [stopCallListener])

    useEffect(() => {
        const resetNavigationTimeout = () => {
            if (navigationTimeoutRef.current) {
                clearTimeout(navigationTimeoutRef.current);
            }
            setNavigationVisible(true);
            navigationTimeoutRef.current = setTimeout(() => {
                setNavigationVisible(false);
            }, 2000); // 2 секунд
        };

        const handleMouseMove = () => {
            resetNavigationTimeout();
        };

        videoRef.current.addEventListener('mousemove', handleMouseMove);

        return () => {
            if (navigationTimeoutRef.current) {
                clearTimeout(navigationTimeoutRef.current);
            }
            if (videoRef.current) {
                videoRef.current.removeEventListener('mousemove', handleMouseMove);
            }
        };
    }, []);

    useBeforeUnload((event) => {
        if (isChatStarted) {
          stopCall();
          event.preventDefault();
          event.returnValue = ''; 
        }
      });


    const blocker = useBlocker(() => !!isChatStarted);
    useEffect(() => {
        if (blocker.state === 'blocked' && isChatStarted) {
        console.log('stopCall')
        stopCall().then(() => {
            blocker.proceed();
        });
        }
    }, [blocker.state]);


    const call = async () => {
        const requestData = {
            breed_id: 0,
            exclusion_users: []
        };
    
        const cookieBreedId = Cookies.get('breed_id');
        const cookieExcludeIds = Cookies.get('exclude_ids');
        requestData.breed_id = cookieBreedId ? cookieBreedId : 0;
        requestData.exclusion_users = cookieExcludeIds ? cookieExcludeIds : [];
    
        setLoading(true);
        setChatStarted(true);
    
        try {
            // Запрашиваем доступ к медиа-устройствам
            const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    
            // Устанавливаем полученный медиа-поток в элемент video
            if (currentUserVideoRef.current) {
                currentUserVideoRef.current.srcObject = mediaStream;
            }
    
            if (mediaStream.getVideoTracks().length > 0) {
                // Доступ к медиа-устройствам получен, отправляем запрос на получение peerId
                const response = await dispatch(getVideochatPeerId(requestData));
                const data = response?.data;
                console.log(data);
    
                if (data && !companionIdInstance.current) {
                    console.log('Видео доступно, можно начинать звонок');
                    if (data.randomUser?.id && !companionIdInstance.current) {
                        const userId = data.randomUser.id.toString();
                        companionIdInstance.current = userId;
    
                        // Начинаем звонок
                        const callInstance = peerInstance.current.call(userId + "parrot2024", mediaStream, {
                            metadata: {
                                userId: user.id,
                                name: user.name,
                                pet_gender: user.pet_gender,
                                pet_name: user.pet_name,
                                friends: user.friends
                            }
                        });
    
                        console.log('data.randomUser', data.randomUser);
                        setCompanionData(data.randomUser);
    
                        // Обработка входящего видеопотока
                        callInstance.on('stream', (remoteStream) => {
                            if (remoteStream.getVideoTracks().length > 0) {
                                if (remoteVideoRef.current) {
                                    remoteVideoRef.current.srcObject = remoteStream;
                                }
                                console.log('remoteVideoRef', remoteVideoRef.current.srcObject);
    
                                const requestData = { isOnCall: true };
                                dispatch(isOnCall(requestData));
                            } else {
                                console.log('Удаленный поток не содержит видео треков, повторная попытка вызова');
                                setTimeout(nextCall, 1000);
                            }
                        });
    
                        // Обработка ошибок соединения
                        callInstance.on('error', (err) => {
                            console.error('Ошибка при установлении соединения:', err);
                            if (!remoteVideoRef.current.srcObject) {
                                console.log('Соединение не установлено, повторная попытка вызова');
                                setTimeout(call, 1000);
                            }
                        });
                    }
                } else {
                    console.error('Ошибка: Видео не доступно');
                    setLoading(true);
                    setChatStarted(false);
                    await dispatch(endCall(requestData));
                    setLoading(false);
    
                    const requestDataEndCall = { isOnCall: false };
                    await dispatch(isOnCall(requestDataEndCall));
                    toast.error("Video not available. Please try again.");
                }
            } else {
                console.error('Ошибка: Видео не доступно');
                setLoading(true);
                setChatStarted(false);
                await dispatch(endCall(requestData));
                setLoading(false);
    
                const requestDataEndCall = { isOnCall: false };
                await dispatch(isOnCall(requestDataEndCall));
                toast.error("Video not available. Please try again.");
            }
        } catch (error) {
            console.error('Ошибка при выполнении запроса:', error);
            setLoading(false);
            setChatStarted(false);
            // Добавить обработку ошибки для случая, если не удается получить доступ к медиа-устройствам
        }
    };
    


    const VideoSetting = () => {
        if (currentUserVideoRef.current?.srcObject){
            setVideoSetting(!isVideoSetting)
            const videoTracks = currentUserVideoRef.current.srcObject.getVideoTracks();
            videoTracks.forEach(track => {
                track.enabled = !track.enabled;
            });    
        }   
    }

    const VoiceSetting = () => {
        if (currentUserVideoRef.current?.srcObject){
            setVoiceSetting(!isVoiceSetting)
            const audioTracks = currentUserVideoRef.current.srcObject.getAudioTracks();
            audioTracks.forEach(track => {
                track.enabled = !track.enabled;
            });
        }   
    }

    const stopCall = async (notEnd) => {
        notEnd = null;
        return new Promise(async (resolve, reject) => {
            try {
                setStopCallListener(!stopCallListener)
                setLoading(true)
                setCompanionData(null)
                setChatStarted(false)
                stopRecording()
                setVoiceSetting(true)
                setVideoSetting(true)
                const requestData = {};
                if (peerInstance.current && peerInstance.current.connections) {
                    const connections = Object.values(peerInstance.current.connections);
                    let isDisconnected = true;
            
                    connections.forEach(connectionsArray => {
                        connectionsArray.forEach(connection => {
                            if (connection.open) {
                                isDisconnected = false;
                            }
                        });
                    });
            
                    if (isDisconnected) {
                        companionIdInstance.current = null;
                    }
                }
                requestData.to = companionIdInstance.current
                if (notEnd) {
                    requestData.notEnd = notEnd;
                }
                
                peerInstance.current.destroy();
                if (currentUserVideoRef?.current?.srcObject) {
                    currentUserVideoRef.current.srcObject.getTracks().forEach(track => track.stop());
                    currentUserVideoRef.current.srcObject = null;
                    remoteVideoRef.current.srcObject = null;
                }
    
                if (companionIdInstance.current) {
                    let excludeIds = Cookies.get('exclude_ids') || '[]'; 
                    excludeIds = JSON.parse(excludeIds); 
                    if (!excludeIds.includes(+companionIdInstance.current)) {
                        excludeIds.push(+companionIdInstance.current);
                        if (excludeIds.length > 2) {
                            excludeIds.shift(); 
                        }
                        Cookies.set('exclude_ids', JSON.stringify(excludeIds), { expires: 1, path: '/', secure: false, sameSite: 'strict' });
                    }
                    Cookies.set('exclude_ids', JSON.stringify(excludeIds), { expires: 1, path: '/', secure: false, sameSite: 'strict' });
                }
                companionIdInstance.current = null;
    
                await dispatch(endCall(requestData));
                setLoading(false);
                
                const requestDataEndCall = {
                    isOnCall: false
                }
                await dispatch(isOnCall(requestDataEndCall)).then(() => {
                    // console.log(2)
                })
                resolve();
            } catch (error) {
                setLoading(false);
                console.error('Ошибка при выполнении end call from component stop call func:', error);
                reject(error);
            }
        });
    }
    
    const nextCall = async () => {
        try {
            console.log(1)
            await stopCall(true);
            call();
        } catch (error) {
            console.error('Error during nextCall:', error);
        }
    };

    const onStartRecord = async e => {
        e.preventDefault()
        dispatch(getVideoLimits()).then(response => {
            const result = response?.data
            if(result === true){
                setRecordTimer(setTimeout(stopRecording, videoTimeLimit.current))
                startRecording()
            } else {
                toast.warn("You have used up your limit.")
            } 
        }).catch(err => {
            console.error('There was an error!', err)
        })
    };
    
    const onStopRecord = e => {
        e.preventDefault()
        clearTimeout(recordTimer)
        stopRecording()
    }

    const startRecording = () => {
        if (remoteVideoRef.current.srcObject && currentUserVideoRef.current.srcObject) {
            const remoteVideo = remoteVideoRef.current;
            const currentUserVideo = currentUserVideoRef.current;
    
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
    
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            const destination = audioContext.createMediaStreamDestination();
    
            const remoteAudioSource = audioContext.createMediaStreamSource(remoteVideo.srcObject);
            remoteAudioSource.connect(destination);
    
            const currentUserAudioSource = audioContext.createMediaStreamSource(currentUserVideo.srcObject);
            currentUserAudioSource.connect(destination);
    
            const canvasStream = canvas.captureStream(30); // Устанавливаем частоту кадров
            const combinedStream = new MediaStream([
                ...canvasStream.getVideoTracks(),
                ...destination.stream.getAudioTracks()
            ]);
    
            // Определяем тип браузера и устанавливаем параметры MediaRecorder
            let options = { mimeType: 'video/webm' }; // Chrome
            const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    
            if (isSafari) {
                options = { mimeType: 'video/mp4' }; // Safari
            }
            console.log(options)
            const recorder = new MediaRecorder(combinedStream, options);
            const chunks = [];
    
            recorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    chunks.push(event.data);
                }
            };
    
            recorder.onstop = async () => {
                const blob = new Blob(chunks, { type: options.mimeType });
                const formData = new FormData();
                formData.append('video', blob);
    
                try {
                    const response = await dispatch(saveVideo(formData)).then(() => {
                        toast.success("Video saved successfully")
                    }).catch(() => {
                        toast.error("Failed saved video. Please try again.")
                    });

                    setRecordingLoading(false);
                    console.log(response?.data);
                } catch (e) {
                    console.log('save video error', e);
                    toast.error("Failed saved video. Please try again.")
                }
            };
    
            recorder.start();
    
            const drawVideoStreams = () => {
                const remoteWidth = remoteVideo.videoWidth;
                const remoteHeight = remoteVideo.videoHeight;
                const currentUserWidth = currentUserVideo.videoWidth;
                const currentUserHeight = currentUserVideo.videoHeight;
    
                canvas.width = remoteWidth;
                canvas.height = remoteHeight;
    
                ctx.drawImage(remoteVideo, 0, 0, remoteWidth, remoteHeight);
    
                const padding = 20;
                const currentUserScaledWidth = 150;
                const currentUserScaledHeight = (currentUserHeight / currentUserWidth) * currentUserScaledWidth;
                const currentUserX = padding;
                const currentUserY = padding;
                ctx.drawImage(currentUserVideo, currentUserX, currentUserY, currentUserScaledWidth, currentUserScaledHeight);
    
                requestAnimationFrame(drawVideoStreams);
            };
    
            drawVideoStreams();
            mediaRecorderInstance.current = recorder;
            setRecord(true);
        }
    };
    
    const stopRecording = () => {
        if (mediaRecorderInstance?.current) {
            console.log('true')
            if (isRecording) {
                setRecordingLoading(true)
            }
            mediaRecorderInstance.current.stop();
            setRecord(false);
        }
    } 

    const onFullScreen = () => {
        const isIPhone = (navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i))
        const device = searchParams.get('device')

        if (isFullscreen) {
            if (device === 'app') {
                onFullScreenIphone()
                return
            }
            if (isIPhone) {
                onFullScreenIphone()
                return
            }
            if (videoRef.webkitSupportsFullscreen) {
                videoRef.webkitExitFullscreen()()
                setFullscreen(!isFullscreen)
                return
            }
            document.exitFullscreen()
            setFullscreen(!isFullscreen)
            return
        }

        if (device === 'app') {
            onFullScreenIphone()
            return
        }
        if (isIPhone) {
            onFullScreenIphone()
            return
        }

        if (videoRef.webkitSupportsFullscreen) {
            videoRef.webkitEnterFullscreen()
            setFullscreen(!isFullscreen)
            return
        }

        videoRef.current.requestFullscreen()
        setFullscreen(!isFullscreen)
    }

    const onFullScreenIphone = () => {
        setFullscreen(!isFullscreen)
        isFullscreen ? videoRef.current.classList.remove('opened') : videoRef.current.classList.add('opened')
    }

    const openReportVideoModal = (e) => {
        e.preventDefault()
        setReportVideoPopup(true)
    }
    const closeReportVideoModal = () => {
        setReportVideoPopup(false)
    }

    const { t } = useTranslation()

    return <div className='container'>
    <div className='videochat' ref={videoRef}>
        <div className='videochat__header'>
            <div className='left_header_info'>
                <div className='fullsize cursor-pointer' onClick={onFullScreen}>
                    {isFullscreen ? <img src={fullscreenExit} alt="Full screen" /> : <img src={fullscreen} alt="Full screen" />}
                </div>
                <div className='note cursor-pointer' onClick={openReportVideoModal}>
                    <img src={note} alt="Report" />
                    <span className='subtitle_big'>{t('home.report_violation')} </span>
                </div>
            </div>
            <ChatName />
            {isRecording ? <div className='recording'>
                    <span className='subtitle_big'>{t('home.chat_recording_message')} </span>
                    <img src={recordingNow} alt="Record" />
                </div> : <div className='w-[230px] hidden md:block'></div>}
            
        </div>
        <div className='fotouser'>
            {!isChatStarted && <>
                {/* <div className="video-overlay"></div> */}
                <div className="overlay-heading">
                    <p className='h2'>{t('home.insist_start_chat')} </p>
                </div>
            </>}
            {isLoading && <div className='videochat__loader animate-spin'><img src={loader} alt='Loader' /></div>}
            <video 
                autoPlay 
                playsInline
                className="cursor-pointer user-video"
                ref={remoteVideoRef}
            ></video>
        </div>
        <div>
            <div className={`another_user ${isChatStarted ? 'active' : ''}`}>
                <video muted playsInline autoPlay ref={currentUserVideoRef}></video>
            </div>
            {companionData ? <TopControls user={companionData} /> : ''}
        </div>
        <div className={`videochat__navigation ${isNavigationVisible ? 'visible' : 'hidden'}`}>
            <div className='settings'>
                {/* <div className='settings__item'>
                    <img src={settingsIcon} alt="Setting" />
                </div> */}
                <div className={`settings__item ${!isVoiceSetting ? 'disabled' : ''}`} onClick={VoiceSetting}>
                    {isVoiceSetting ? <img src={voiceIcon} alt="Voice" /> : <img src={voiceIconOff} alt="Voice" />}
                </div>
                <div className={`settings__item ${!isVideoSetting ? 'disabled' : ''}`} onClick={VideoSetting} >
                    {isVideoSetting ? <img src={videoIcon} alt="Video" /> : <img src={videoIconOff} alt="Video" />}
                </div>
            </div>
            <div className='buttons_start_end'>
                {!isChatStarted ?
                    <Button tag="button" onClick={call} disabled={!isAbleToStart}>{t('home.start_chat_uppercase_text')}</Button> : 
                    <Button tag="button" className='btn_stop' iconLeft={stopIcon} onClick={stopCall}>{t('home.stop')}</Button>
                }
                <Button tag="button" onClick={nextCall} disabled={!companionData} iconRight={nextIcon}>{t('home.next')}</Button>
            </div>
            {isRecording ? <Link to="/" className={`recording_stop ${recordingLoading ? 'loading' : ''}`} 
                onClick={(e) => {onStopRecord(e)}}
            >
                <span className={`record-icon recording`}></span>
                <div className='animate-spin'><img src={loader} alt='Loader' /></div>
                <span className='subtitle_medium'>
                    {recordingLoading ? 'Loading' : t('home.stop_recording')}
                </span>
            </Link> : <Link to="/" className={`recording_stop ${recordingLoading ? 'loading' : ''}`} 
                onClick={(e) => {onStartRecord(e)}}
            >
                <span className={`record-icon`}></span>
                <div className='animate-spin'><img src={loader} alt='Loader' /></div>
                <span className='subtitle_medium'>
                    {recordingLoading ? 'Loading' : t('home.start_recording')}
                </span>
            </Link>}
        </div>
    </div>
    <Modal
        isOpen={reportVideoPopup}
        onRequestClose={closeReportVideoModal}
        className="popup"
        overlayClassName="popup__overlay"
        shouldCloseOnOverlayClick={true}
    >
        <div className="popup__content" >
            <div className="popup__close" onClick={closeReportVideoModal}><img src={cross} alt="Close" /></div>
            <PopupReportVideo companionData={companionData} />
        </div>
    </Modal>
</div>
}

export default React.memo(Videochat)