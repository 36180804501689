import {useEffect, useRef, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import { Link, useNavigate } from 'react-router-dom'
import Button from '../../ui/Button'
import arrow from '../../assets/img/icons/arrow.svg'
import { useForm } from 'react-hook-form'
import { uploadProfilePhoto } from '../../store/actions/profile/index';
import { useSelector, useDispatch } from "react-redux"
import FormLoader from '../../ui/FormLoader';
import { getUserInfo } from '../../store/actions/auth';
import { useTranslation } from 'react-i18next'
import { generateLink } from '../../utils/i18n';

const SignupUploadPhoto = () => {
    const [sizeRange, setSize] = useState(0)
    const [angleRange, setAngle] = useState(0)
    const [files, setFiles] = useState([])
    const [isPhotoUpdated, setPhotoUpdated] = useState(false)
    
    const profileLoading = useSelector(state => state.profile.profileLoading)
    const profileError = useSelector(state => state.profile.profileError)
    const imageRef = useRef(null)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const {getRootProps, getInputProps} = useDropzone({
      accept: {
        'image/*': []
      },
      onDrop: acceptedFiles => {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        })));
      }
    })
    const {
        handleSubmit
    } = useForm()

    const thumbScale = (+('1.' + sizeRange)) - 0.1
    const thumbAngle = angleRange - 1
    const thumbsStyle = {
        transform: `scale(${thumbScale}) rotate(${thumbAngle}deg)`
    }
    
    const thumbs = files.map(file => (
        <img
            ref={imageRef}
            src={file.preview}
            key={file.name}
            alt="Thumb"
            style={thumbsStyle}
            onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />
    ))

    useEffect(() => {
        const onPhotoChange = () => {
            if(files?.length) {
                setSize(1)
                setAngle(1)
            }
        }
        onPhotoChange()
        return () => files.forEach(file => URL.revokeObjectURL(file.preview))
    }, [files])

    const photoSubmit = () => {
        const formData = new FormData()
        formData.append('image', files[0])
        dispatch(uploadProfilePhoto(formData)).then(response => {
            console.log("Фото успішно оновлено!", response);
            dispatch(getUserInfo()).then(() => {
                return navigate('/profile')
            })
        }).catch(error => {
            console.error("Помилка оновлення фото:", error)
        })
    }

    //crop photo
    const handleRotationChange = (e) => {
        const newRotation = e.target.value
        setAngle(newRotation)
        updateTransform()
    }

    const handleScaleChange = (e) => {
        const newScale = e.target.value
        setSize(newScale)
        updateTransform()
    }

    const updateTransform = () => {
        imageRef.current.style.transform = `rotate(${angleRange}deg) scale(${sizeRange})`;
    }

    const saveImage = async () => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        const image = imageRef.current

        const scale = parseFloat(sizeRange);
        const angle = parseFloat(angleRange) * (Math.PI / 180)

        const width = image.naturalWidth
        const height = image.naturalHeight

        const cos = Math.abs(Math.cos(angle));
        const sin = Math.abs(Math.sin(angle));
        const newWidth = width * cos + height * sin;
        const newHeight = width * sin + height * cos;

        canvas.width = newWidth * scale;
        canvas.height = newHeight * scale

        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate(angle);
        ctx.scale(scale, scale);
        ctx.drawImage(image, -width / 2, -height / 2, width, height)

        canvas.toBlob((blob) => {
            const formData = new FormData()
            formData.append('image', blob, 'image.png')
            dispatch(uploadProfilePhoto(formData)).then(response => {
                console.log("Фото успішно оновлено!", response)
                setPhotoUpdated(true)
                dispatch(getUserInfo()).then(() => {
                    return navigate('/profile')
                })
            }).catch(error => {
                console.error("Помилка оновлення фото:", error)
            })
        }, 'image/png')
    }

    return <div className='form-wrap form-wrap__registration form-wrap__profile-photo'>
        <Link to={generateLink('/subscription')} className='back-link'>
            <img src={arrow} alt="back" />
        </Link>
        {profileLoading && <FormLoader />}
        <form className="upload-step" onSubmit={handleSubmit(saveImage)} encType='multipart/form-data'>
            <h2 className='popup__heading h2'>{t('authorization.last_step')}</h2>
            <span className='popup__description subtitle_medium'>{t('authorization.upload_pet_image')}</span>
            <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <div className='change-photo-profile'>
                    {thumbs.length ? thumbs : <div className='change-photo-profile__text-part'>
                        <div className='text_small'><p className='subtitle_medium'>{t('authorization.click_upload')}</p> <span>{t('authorization.drug_drop')}</span> <br /> {t('authorization.format_img')}</div>
                    </div>}
                </div>
            </div>
            <div className='photo-ranges'>
                <div className='photo-range'>
                    <span className='photo-range__title subtitle_medium'>{t('authorization.size')}</span>
                    <div className="photo-range__input">
                        <input type="range" min="1" max="9" value={sizeRange} id="range_size" onChange={handleScaleChange} disabled={!sizeRange} /> 
                    </div>
                </div>
                <div className='photo-range'>
                    <span className='photo-range__title subtitle_medium'>{t('authorization.angular')}</span>
                    <div className="photo-range__input">
                        <input type="range" min="1" max="360" id="range_angle" value={angleRange} onChange={handleRotationChange}  disabled={!angleRange}/> 
                    </div>
                </div>
            </div>
            <div className="btns-flex">
                <Button to="/"
                    type="secondary" 
                >{t('authorization.do_later')}</Button>
                {files?.length ? <Button 
                    tag="button"
                >{t('authorization.save')}</Button> : ''}
            </div>
            {profileError && <p className='form__error'>{t('general.wrong_message')}</p>}
            {isPhotoUpdated && <p className='success'>{t('general.photo_update_successfully')}</p>}
        </form>
    </div>
}

export default SignupUploadPhoto