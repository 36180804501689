import { Link } from "react-router-dom"
import arrow from '../assets/img/icons/arrow.svg'
import addfriendIcon from '../assets/img/icons/add_friends.svg'
import { connect } from "react-redux"
import { useEffect } from "react"
import { getAllUsers, getFriendsRequests, sendFriendRequest } from "../store/actions/friends/friends"
import Button from "../ui/Button"
import { useTranslation } from 'react-i18next'
import { generateLink } from '../utils/i18n';

const UsersList = ({allUsers, friendsRequests, getAllUsers, getFriendsRequests, sendFriendRequest}) => {

    useEffect(() => {
        getAllUsers()
    }, [getAllUsers])

    useEffect(() => {
        getFriendsRequests()
    }, [getFriendsRequests])

    const onSendRequest = (id) => {
        sendFriendRequest(id)
    }
    const { t } = useTranslation()

    return <div className='content'>
        <div className="container">
            <Link to={generateLink('/')} className='back-link'>
                <img src={arrow} alt="link to back" />
                <span className='subtitle_big'>{t('general.back_chat')}</span>
            </Link>
            <div className='card-widget'>
                {allUsers?.length && allUsers.map(u => {
                    return <div className="bg-[#888] rounded-[8px] my-5 p-2 flex justify-between items-center" key={u.id}>
                        <p className="h4">{u.name}</p>
                        <Button tag="friend" className="btn add_friend" onClick={() => onSendRequest(u.id)}>
                            <span className='h4'>{t('home.add_friend_text')}</span>
                            <img src={addfriendIcon} alt="Add friend" />
                        </Button>
                        {/* {friendsRequests?.length && friendsRequests.map((r) => {
                            return r.id !== u.id ? 
                                <Button tag="friend" className="btn add_friend" onClick={() => onSendRequest(u.id)} key={r.id}>
                                    <span className='h4'>ADD FRIEND</span>
                                    <img src={addfriendIcon} alt="Add friend" />
                                </Button> : <p className="h4" key={r.id}>Request has already sent</p>
                        })} */}
                    </div>
                }) }
            </div>
        </div>
    </div>
}

const mapStateToProps = (state) => ({
    allUsers: state.friends.allUsers,
    friendsRequests: state.friends.friendsRequests,
})

const mapDispatchToProps = (dispatch) => ({
    getAllUsers: () => dispatch(getAllUsers()),
    getFriendsRequests: () => dispatch(getFriendsRequests()),
    sendFriendRequest: (id) => dispatch(sendFriendRequest(id)),
})
export default connect(mapStateToProps, mapDispatchToProps)(UsersList)