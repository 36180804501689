import { Link } from 'react-router-dom'
import arrow from '../assets/img/icons/arrow.svg'
import SavedVideosList from '../modules/saved-videos/SavedVideosList'
import { useTranslation } from 'react-i18next'
import {useCookies} from 'react-cookie'
import arrow_dark from '../assets/img/icons/arrow_dark.svg' 
import { generateLink } from '../utils/i18n';

const SavedVideos = () => {
    const { t } = useTranslation()
    const [cookies, setCookie] = useCookies(['theme', 'language'])
    return <div className='content'>
            <div className="container">
                <Link to={generateLink('/')} className='back-link'>
                    <img src={cookies?.theme === 'dark' ? arrow : arrow_dark} alt="link to back" />
                    <span className='subtitle_big'>{t('general.back_chat')}</span>
                </Link>
                <SavedVideosList />
            </div>
        </div>
}

export default SavedVideos
