export const apiConfig = {
    url: process.env.REACT_APP_API_URL,
    login: '/login',
    refreshToken: '/refresh',
    signup: '/register',
    me: '/me',
    forgotPassword: '/forgot-password',
    profile: {
        update: '/update',
        updatePassword: 'update-password',
        updateEmail: 'update-email',
        deletePhoto: 'delete-photo'
    },
    verifyEmail: '/verify-email',
    getBreeds: '/breeds',
    friends: {
        get: '/friends',
        delete: '/friends/delete',
        send: '/friends/send-request',
        accept: '/friends/accept-request',
        requests: '/friends/requests',
    },
    videochat: {
        getPeerId: '/users-by-bread',
        setBreed: '/choose-breed',
        endCall: '/end-call',
        isOnCall: '/is-on-call',
        saveVideo: '/save-video',
        callFriend: '/call-friend',
        getVideoLimits: '/get-video-limits',
        getTimeLimit: '/get-time-limit',
        addReport: '/add-report',
    },
    addOnline: '/add-online',
    savedVideos: {
        get: '/get-videos',
        delete: '/delete-video'
    },
    subscription: {
        getPayments: '/payments',
        subscribe: '/subscribe',
        unsubscribe: '/unsubscribe',
        changeCard: '/change-card',
    },
    translations: '/translations'
}