import { useTranslation } from 'react-i18next';
import { generateLink } from '../utils/i18n';

const Offerta = () => {
    const { t } = useTranslation();

    return (
        <div className='content'>
            <ul className="tabs">
                <li data-tab="terms">
                    <a href={generateLink('/terms-and-conditions')}>Terms and conditions</a>
                </li>
                <li data-tab="privacy">
                    <a href={generateLink('/privacy-policy')}>Privacy policy</a>
                </li>
                <li className="active" data-tab="offerta">
                    <a href={generateLink('/offerta')}>Offert</a>
                </li>
            </ul>
            <div className="container">
                <div className="text_privacy" dangerouslySetInnerHTML={{ __html: t('privacy.offerta_text') }} />
            </div>
        </div>
    );
}

export default Offerta;
