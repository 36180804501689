import breed from '../assets/img/icons/breed-example.png'
import avatar from '../assets/img/icons/avatar-example.png'
import icon_boy from "../assets/img/icons/gender-boy.svg"
import icon_girl from "../assets/img/icons/gender-girl.svg"
import icon_boy_dark from "../assets/img/icons/gender-boy-dark.svg"
import icon_girl_dark from "../assets/img/icons/gender-girl-dark.svg"
import Pusher from 'pusher-js'
import Echo from 'laravel-echo'
// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { hour: 'numeric', minute: 'numeric', month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const formatDateShort = (value, formatting = { day: 'numeric', month: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
// export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => (isUserLoggedIn() ? JSON.parse(localStorage.getItem('currentUser')) : null)

export const isUserLoggedIn = () => {
  return localStorage.getItem('currentUser') && localStorage.getItem('token')
}
export const profileData = {
        name: "Roman",
        parrot_name: "Puko",
        breed: "Cacatoo",
        sex: "male",
        avatar: avatar,
        online: true,
        friends: [],
        notifications: [
            {id: 1, type: 'video_request', friend: {id: 11,name: 'Nicknameofmyfriend115', breed: 'Cockatoo', photo: breed}},
            {id: 2, type: 'video_request', friend: {id: 22,name: 'Nicknameofmyfriend115', breed: 'Cockatoo', photo: breed}}
        ],
        subscribe: false,
        saved_videos: [],
        card: null,
        transaction_history: [
          {id: 1, date: '23.01.2024', time: '10:34 AM', payment_card: '1111', amount: '$5'},
          {id: 2, date: '23.02.2024', time: '09:34 AM', payment_card: '1111', amount: '$5'},
        ]
}

export const breedsData = [
  {id: 1, photo: breed, name: 'Cockatoo'},
]
export const genderOptions = [
  {id: 1, value: 'boy', label: 'Boy', icon: icon_boy, icon_dark: icon_boy_dark},
  {id: 2, value: 'girl', label: 'Girl', icon: icon_girl, icon_dark: icon_girl_dark},
]
export const selectClassNames = {
  control: () =>
    'select',
  option: () =>
    'select-option',
  menu: () =>
    'select-menu',
  input: () =>
    'select-input',
  placeholder: () =>
  'select-placeholder',
  singleValue: () =>
  'select-value',
}

export const storageUrl = `${process.env.REACT_APP_URL}/storage`
export const videoStorageUrl = `${process.env.REACT_APP_VIDEO_STORAGE_URL}/`
// export const storageUrl = `https://parrot-api.strymba.com/storage`

export const DefaultAvatar = ({alt = 'Avatar'}) => {
  return <img src={avatar} alt={alt} />
}

window.Pusher = Pusher
export const echoLaravel = new Echo ({
  broadcaster: 'pusher',
  // key: '12e401ba29ffa1f99f4b',
  key: '1a65b15a1ac0fa78a29a', //Tolya pusher
  cluster: 'eu',
  forceTLS: true,
  auth: {
    headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  },
  authEndpoint: `${process.env.REACT_APP_URL}/broadcasting/auth`
})