// createPeer.js
import Peer from 'peerjs';

const createPeer = (userId) => {
    return new Peer(userId + 'parrot2024', {
        config: {
            iceServers: [
                { 
                    urls: 'turn:185.65.246.54:3478', 
                    username: 'parrot_turn_user', 
                    credential: 'parrot_turn_qnA2p' 
                },
                { 
                    urls: 'turn:185.65.246.54:3478?transport=tcp', 
                    username: 'parrot_turn_user', 
                    credential: 'parrot_turn_qnA2p' 
                }
            ]
        },
    });
}

export default createPeer
