
import SubscriptionActive from '../../modules/Subscription/components/SubscriptionActive'
import SubscriptionUnctive from '../../modules/Subscription/components/SubscriptionUnactive'
import TransactionHistory from '../../modules/Subscription/components/TransactionHistory'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState} from 'react'
import loader from '../../assets/img/icons/loader.svg'
import { getPayments } from '../../store/actions/subscription/index.js'
import BackLink from '../../components/BackLink.jsx'

const SubscriptionModule = () => {
    const [isPaidBefore, setIsPaidBefore] = useState(null);
    const [profileData, setProfileData] = useState(null);
    const user = useSelector(state => state.auth.currentUser)
    const loading = useSelector(state => state.subscription.loading)
    const isSubscribed = user?.is_subscribed
    
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getPayments()).then(response => {
            console.log('get payments response: ', response);
            const payments = response?.data
            setIsPaidBefore(payments?.length ? true : false);
            setProfileData(payments)
        }).catch(error => {
            console.error('Get payments error:', error) 
        })
    }, []);

    if (loading) {
        return <div className='videochat__loader animate-spin'><img src={loader} alt='Loader' /></div>; 
    }

    return (
        <div className="container">
            <BackLink />

            {isSubscribed ? <SubscriptionActive data={profileData} /> : <SubscriptionUnctive isPaidBefore={isPaidBefore} />}
            {isPaidBefore && <TransactionHistory data={profileData} />}
        </div>
    );
}
export default SubscriptionModule