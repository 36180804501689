import chatIcon from '../../../assets/img/icons/chat_icon.svg'
import friendsIcon from '../../../assets/img/icons/friends_icon.svg'
import recordChatIcon from '../../../assets/img/icons/record_chat_icon.svg'
import cloudIcon from '../../../assets/img/icons/cloud_icon.svg'
import visaCard from '../../../assets/img/visa_card.png'
import changeIcon from '../../../assets/img/icons/change_icon.svg' 
import parrotChatImg from '../../../assets/img/parrot_bcg_subscription.png'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { PopupCancelSuscription, PopupReplaceCard } from '../../../components/Popups/Popups'
import Modal from 'react-modal'
import cross from '../../../assets/img/icons/cross-white.svg'
import { useTranslation } from 'react-i18next'

const SubscriptionActive = ({ data }) => {
    const [cancelSubscriptionPopup, setCancelSubscriptionPopup] = useState(false)
    const [replaceCardPopup, setReplaceCardPopup] = useState(false)
    const firstCardNumber = Array.isArray(data) && data.length > 0 ? data[0].sender_card_mask2 : '';
    const starIndex = firstCardNumber.indexOf('*');
    const beforeStars = firstCardNumber.slice(0, starIndex + 1);
    const afterStars = firstCardNumber.slice(starIndex + 1);
    const additionalStars = '*'.repeat(7);
    const cardMask = `${beforeStars}${additionalStars}${afterStars}`;


    const openCancelSuscriptionModal = (e) => {
        e.preventDefault()
        setCancelSubscriptionPopup(true)
    }
    const closeCancelSuscriptionModal = () => {
        setCancelSubscriptionPopup(false)
    }
    const openReplaceCardModal = (e) => {
        e.preventDefault()
        setReplaceCardPopup(true)
    }
    const closeReplaceCardModal = () => {
        setReplaceCardPopup(false)
    }
    const { t } = useTranslation()

    return <div className='subscription-card card-widget'>
        <div className='subscription-card__header'>
            <div className='flex'>
                <div className='subscription-card__header-heading'>
                    <span className='h2'>{t('subscriptions.period_subscription')}</span>
                    <span className='subtitle_big'><b className='h2'>{t('general.subscribe_price')}</b>/{t('subscriptions.period')}</span>
                </div>
                <span className='btn-status h4 active'>
                    {t('general.active_btn')}
                </span>
            </div>
            <div className='delete' onClick={openCancelSuscriptionModal}>
                <span className='subtitle_medium'>{t('general.subscribe_cancel')}</span>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M10 4C9.44772 4 9 4.44772 9 5H15C15 4.44772 14.5523 4 14 4H10ZM17 7H7V17C7 18.6569 8.34315 20 10 20H14C15.6569 20 17 18.6569 17 17V7ZM5 7V17C5 19.7614 7.23858 22 10 22H14C16.7614 22 19 19.7614 19 17V7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H17C17 3.34315 15.6569 2 14 2H10C8.34315 2 7 3.34315 7 5H4C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7H5ZM10 9C10.5523 9 11 9.44772 11 10V17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17V10C9 9.44772 9.44772 9 10 9ZM14 9C14.5523 9 15 9.44772 15 10V17C15 17.5523 14.5523 18 14 18C13.4477 18 13 17.5523 13 17V10C13 9.44772 13.4477 9 14 9Z" fill="#94A8B4"/>
                </svg>
            </div>
        </div>
        <div className='subscription-card__benefits'>
            <h3 className='subtitle_big subscription-card__subtitle'>{t('subscriptions.benefits_title')}</h3>
            <div className='flex-benefits'>
                <div className='flex-benefits__row'>
                    <img src={chatIcon} alt="chat icon" />
                    <span className='subtitle_big'>{t('subscriptions.individual_chats')}</span>
                </div>
                <div className='flex-benefits__row'>
                    <img src={friendsIcon} alt="friends icon" />
                    <span className='subtitle_big'>{t('subscriptions.add_friends_unlimitedly_text')}</span>
                </div>
                <div className='flex-benefits__row'>
                    <img src={recordChatIcon} alt="recird chat icon" />
                    <span className='subtitle_big'>{t('subscriptions.record_chat_text')} </span>
                </div>
                <div className='flex-benefits__row'>
                    <img src={cloudIcon} alt="cloud icon" />
                    <span className='subtitle_big'>{t('subscriptions.save_videos_cloud_text')}</span>
                </div>
            </div>
        </div>
        <div className='subscription-card__payment'>
            <h3 className='subtitle_big subscription-card__subtitle'>{t('subscriptions.payment_card_title')}</h3>
            <div className='payment-wrapper'>
                <div className='payment-wrapper__visa-card'>
                    <div className="card_number h2">{cardMask}</div>
                    <img src={visaCard} alt="card" /> 
                </div>
                <Link to="/" className='btn-change-card' onClick={openReplaceCardModal}>
                    <span className='h4'>{t('subscriptions.change_card')}</span>
                    <img src={changeIcon} alt="change icon" />
                </Link>
            </div>
        </div>
        <div className='parrot-message'>
            <div className='chat-message'>
                <p className='h4'>{t('subscriptions.message_thankyou')}</p>
            </div>
            <img src={parrotChatImg} alt="parrot" />
        </div>

        <Modal
            isOpen={cancelSubscriptionPopup}
            onRequestClose={closeCancelSuscriptionModal}
            className="popup"
            overlayClassName="popup__overlay"
            shouldCloseOnOverlayClick={true}
        >
            <div className="popup__content" >
                <div className="popup__close" onClick={closeCancelSuscriptionModal}><img src={cross} alt="Close" /></div>
                <PopupCancelSuscription onCloseModal={closeCancelSuscriptionModal} />
            </div>
        </Modal>
        <Modal
            isOpen={replaceCardPopup}
            onRequestClose={closeReplaceCardModal}
            className="popup"
            overlayClassName="popup__overlay"
            shouldCloseOnOverlayClick={true}
        >
            <div className="popup__content" >
                <div className="popup__close" onClick={closeReplaceCardModal}><img src={cross} alt="Close" /></div>
                <PopupReplaceCard onCloseModal={closeReplaceCardModal} />
            </div>
        </Modal>
    </div>
}
export default SubscriptionActive