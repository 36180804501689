import Input from "../../ui/Input";
import { useForm } from 'react-hook-form';
import Button from "../../ui/Button";
import { forgotPassword } from "../../store/actions/auth/index.js";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css"
import arrow from '../../assets/img/icons/arrow.svg'
import arrow_dark from '../../assets/img/icons/arrow_dark.svg' 
import {useCookies} from 'react-cookie'
import { generateLink } from '../../utils/i18n'
import { Link } from 'react-router-dom'

const ForgotPasswordForm = () => {
    const [cookies, setCookie] = useCookies(['theme', 'language']);
    const { register, handleSubmit } = useForm()
    const dispatch = useDispatch()
    
    const onSubmit = async (data) => {
        dispatch(forgotPassword(data)).then(() => {
            toast.success("Reset link has been sent to your email!")
        }).catch(err => {
            console.error('There was an error!', err);
        })
    };

    return <div className="form-wrap">
        <div className="popup__change-email">
            <Link to={generateLink('/')} className='back-link'>
                <img src={cookies?.theme === 'dark' ? arrow : arrow_dark} alt="link to back" />
            </Link>
            <h2 className='popup__heading h2'>Recover your password</h2>
            <span className='popup__description subtitle_big'>We will send you a confirmation code to change your password.</span>
            <form className='popup__form' onSubmit={handleSubmit(onSubmit)}>
                <Input
                    placeholder="Enter your E-mail"
                    name="email"
                    type="text"
                    register={register}
                />
                <Button
                    tag="button" 
                    type="primary"
                >
                    Confirm
                </Button>
            </form>
        </div>
    </div>
}
export default ForgotPasswordForm