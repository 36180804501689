import actionTypes from '../../action-types/subscriptionTypes'
import { http } from '../../../helpers'
import { toast } from 'react-toastify'
import { apiConfig } from '../../../api/api.config'

export const getPayments = () => async (dispatch) => {
  dispatch({ type: actionTypes.SUBSCRIPTION_LOADING, loading: true })
  dispatch({ type: actionTypes.SUBSCRIPTION_ERROR, error: null })

  return http
    .get(apiConfig.subscription.getPayments)
    .then(response => {
        dispatch({ type: actionTypes.SUBSCRIPTION_LOADING, loading: false })
        dispatch({ type: actionTypes.SUBSCRIPTION_ERROR, error: null })
      if (response?.data) {
        dispatch({
          type: actionTypes.SUBSCRIPTION_INFO,
          data: response.data
        })
      }
      return response
    })
    .catch(err => {
      toast.error(err.message);
      dispatch({ type: actionTypes.SUBSCRIPTION_LOADING, loading: false })
      dispatch({ type: actionTypes.SUBSCRIPTION_ERROR, error: err })
      throw err
    })
}

export const setSubscribe = (data) => async (dispatch) => {
    dispatch({ type: actionTypes.SUBSCRIPTION_LOADING, loading: true })
    dispatch({ type: actionTypes.SUBSCRIPTION_ERROR, error: null })
  
    return http
      .post(apiConfig.subscription.subscribe, data)
      .then(response => {
          dispatch({ type: actionTypes.SUBSCRIPTION_LOADING, loading: false })
          dispatch({ type: actionTypes.SUBSCRIPTION_ERROR, error: null })
        return response
      })
      .catch(err => {
        toast.error(err.message);
        dispatch({ type: actionTypes.SUBSCRIPTION_LOADING, loading: false })
        dispatch({ type: actionTypes.SUBSCRIPTION_ERROR, error: err })
        throw err
      })
}

export const cancelSubscribe = () => async (dispatch) => {
    dispatch({ type: actionTypes.SUBSCRIPTION_LOADING, loading: true })
    dispatch({ type: actionTypes.SUBSCRIPTION_ERROR, error: null })
  
    return http
      .patch(apiConfig.subscription.unsubscribe)
      .then(response => {
          dispatch({ type: actionTypes.SUBSCRIPTION_LOADING, loading: false })
          dispatch({ type: actionTypes.SUBSCRIPTION_ERROR, error: null })
        return response
      })
      .catch(err => {
        toast.error(err.message);
        dispatch({ type: actionTypes.SUBSCRIPTION_LOADING, loading: false })
        dispatch({ type: actionTypes.SUBSCRIPTION_ERROR, error: err })
        throw err
      })
}

export const changePaymentCard = (data) => async (dispatch) => {
    dispatch({ type: actionTypes.SUBSCRIPTION_LOADING, loading: true })
    dispatch({ type: actionTypes.SUBSCRIPTION_ERROR, error: null })
  
    return http
      .post(apiConfig.subscription.changeCard, data)
      .then(response => {
          dispatch({ type: actionTypes.SUBSCRIPTION_LOADING, loading: false })
          dispatch({ type: actionTypes.SUBSCRIPTION_ERROR, error: null })
        return response
      })
      .catch(err => {
        toast.error(err.message);
        dispatch({ type: actionTypes.SUBSCRIPTION_LOADING, loading: false })
        dispatch({ type: actionTypes.SUBSCRIPTION_ERROR, error: err })
        throw err
      })
}
