import ForgotPasswordForm from "../modules/auth/ForgotPasswordForm.jsx";

const ForgotPassword = () => {

    return (
        <div className='content'>
            <ForgotPasswordForm />
        </div>
    );
};

export default ForgotPassword;
